import React from "react";

import { Footer, Navigation, Page } from "@Components/layout";
import { useNavigationQuery } from "@Hooks/navigation";
import { useSectionQuery, useSectionTypeParser } from "@Hooks/section";

const IndexPage = () => {
  const sectionQuery = useSectionQuery("index");
  const navigationQuery = useNavigationQuery();

  return (
    <Page>
      <Navigation {...navigationQuery} />
      {sectionQuery?.map((section, index) => {
        const SectionComponent = useSectionTypeParser(section.layout);
        return SectionComponent ? (
          <div id={section.name} key={index}>
            <SectionComponent {...section.body} />
          </div>
        ) : undefined;
      })}
      <Footer background="blue" />
    </Page>
  );
};

export default IndexPage;
