import { graphql, useStaticQuery } from "gatsby";

import { NavigationQuery } from "@Models/navigation";

const useNavigationQuery = (): NavigationQuery => {
  const query = useStaticQuery(graphql`
    query NavigationQuery {
      contentfulNavigationList {
        navigations {
          kind
          name
          url
        }
        badge {
          description
          file {
            url
          }
        }
      }
    }
  `);
  return {
    badge: {
      description: query.contentfulNavigationList.badge.description,
      url: query.contentfulNavigationList.badge.file.url,
    },
    navigations: query.contentfulNavigationList.navigations,
  };
};

export default useNavigationQuery;
