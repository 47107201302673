import {
  CenterSection,
  ContactSection,
  HeroSection,
  TestimonialSection,
  TwoColumnsSection,
} from "@Components/layout";
import { SectionProps } from "@Models/section";

const sectionComponents: {
  [key: string]: (props: SectionProps) => JSX.Element;
} = {
  center: CenterSection,
  contact: ContactSection,
  hero: HeroSection,
  testimonial: TestimonialSection,
  twoColumns: TwoColumnsSection,
};

const useSectionTypeParser = (layout: string) => {
  return sectionComponents[layout];
};

export default useSectionTypeParser;
