import { graphql, useStaticQuery } from "gatsby";

import { SectionQuery } from "@Models/section";

interface SectionQueryRaw {
  body: { internal: { content: string } };
  layout: string;
  name: string;
}

interface SectionListQueryRaw {
  page: string;
  sections: SectionQueryRaw[];
}

const useSectionQuery = (page: string): SectionQuery[] | undefined => {
  const query = useStaticQuery(graphql`
    query SectionQuery {
      allContentfulSectionList {
        nodes {
          page
          sections {
            body {
              internal {
                content
              }
            }
            layout
            name
          }
        }
      }
    }
  `);
  const sectionRaw: SectionListQueryRaw | undefined =
    query.allContentfulSectionList.nodes.filter(
      (raw: SectionListQueryRaw) => raw.page === page
    )[0];
  return sectionRaw?.sections.map((section: SectionQueryRaw) => {
    return {
      body: JSON.parse(section.body.internal.content),
      layout: section.layout,
      name: section.name,
    };
  });
};

export default useSectionQuery;
